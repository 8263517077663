import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate, useParams, useLocation } from "@reach/router";
import Handlebars from "handlebars";
import moment from "moment";
import axios from "axios";
import { Button, Grid } from "@material-ui/core";
import CustomCarousel from "../../../components/custom-carousel";
import ReactHtmlParser from "react-html-parser";
import {
    successQuote,
    patchPlan,
    getQuote,
    updateQuotation,
} from "../../../actions/quoteActions";
import {
    putUpdateQuotation,
    putUpdateQuotationScreenValues,
} from "../../../actions/serviceActions";
import {
    getInsuranceById,
    resetInsurance,
} from "../../../actions/insuranceActions";
import { getTemplateById } from "../../../actions/templateAction";
import CheckCoverage from "../../../components/proposal/checkCoverage";
import LayoutLoading from "../../../components/layout/layoutLoading";
import LayoutError from "../../../components/layout/layoutError";
import AmountCoverage from "../../../components/proposal/amountCoverage";
import { COTIZADA } from "../../../utils/scores";
import { useQuote } from "../../../hooks/useQuote";
import { useHandleLeadSource } from "../../../hooks/useHandleLeadSource";
import "../../../styles/proposal.css";
import ProposalTabs from "../../../components/proposal/proposalTabs";
import Utils from "../../../utils/utils";
import settings from "../../../settings";

const ProposalComponent = ({ riskType }) => {
    riskType = riskType && riskType.toUpperCase();

    const dispatch = useDispatch();

    const lead = useSelector((state) => state.lead);
    const insurances = useSelector((state) => state.insurance);
    const template = useSelector((state) => state.template);
    const [planCard, setPlanCard] = useState(0);
    const [index, setIndex] = useState(1);
    const [proposalsToShow, setProposalsToShow] = useState(3);
    const [optionsByQuote, setOptionsByQuote] = useState(null);
    const [isSendMail, setIsSendMail] = useState(false);
    const [currentInsurance, setCurrentInsurance] = useState({});
    const [trigger, setTrigger] = useState(true);
    const quoteAllPlans = useSelector((state) => state.quoteAllPlans);

    const quote = useQuote(riskType);
    const { quotation, plan } = quote;
    const location = useLocation();
    const params = useParams();
    const now = moment().add(1, "day");
    const end = now.clone().add(1, "y");

    const { products, quotationScreenValues, quotationSectionType } = quotation;

    useHandleLeadSource({
        isNew: true,
        status: COTIZADA,
        trackingStrings: [
            "Ingreso de datos cotización",
            "Cotización de Productos",
        ],
        riskType,
        trigger,
    });

    const getOptionsByQuote = () => {
        if (params && params.insuranceId) {
            const options = insurances.data.filter(
                (data) => data._id == params.insuranceId
            );
            setOptionsByQuote(options && options[0].options);
        }
    };

    useEffect(() => {
        if (quotation !== undefined && quotation.insuranceId) {
            getOptionsByQuote();
            const insurance = insurances.data.find(
                ($in) => $in._id === quotation.insuranceId
            );
            setCurrentInsurance(insurance);

            if (Array.isArray(template.data) && template.data.length === 0) {
                dispatch(getTemplateById(insurance.options.layoutQuotation));
            }
        }
    }, [insurances && insurances.data.length > 0 && quotation]);

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            getQuoteInfo();
        }
    }, []);

    useEffect(() => {
        if (Object.keys(template.data).length > 0 && !isSendMail) {
            sendMail();
        }
    }, [lead, template]);

    useEffect(() => {
        if (!!quotationScreenValues && quotationScreenValues.termsContainer) {
            navigate("sumary");
        }
    }, [quotationScreenValues && quotationScreenValues.termsContainer]);

    useEffect(() => {
        function handleResize() {
            setProposalsToShow(qtyItemsByScreenWidth);
        }

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            dispatch(resetInsurance());
        };
    }, []);

    useEffect(() => {
        initInsurance();
        /* if (!quotation.productSelected) {
            handleSelectPlan(0);
        } */
    }, []);

    useEffect(() => {
        if (typeof products !== "undefined") {
            if (
                quotation.productSelected &&
                quotation.productSelected !== quote.plan._id
            ) {
                updateStateQuotation(plan._id);
                const indexProductSelected = products.findIndex(
                    (product) => product._id === plan._id
                );
                handleSelectPlan(indexProductSelected, plan);
                setPlanCard(
                    indexProductSelected > 0 ? indexProductSelected : 0
                );
            }
            setTrigger(false);
        }
    }, [quotation.productSelected]);

    useEffect(() => {
        if (quote.success) dispatch(successQuote(false));
    }, [quote.success]);

    const sendMail = async () => {
        let quotationData = {};
        if (
            lead &&
            lead.data._id &&
            quotation &&
            quotation._id &&
            !isSendMail &&
            quotationScreenValues &&
            !Utils.isEmptyObject(quotationScreenValues) &&
            !quotationScreenValues.isAlreadySendWelcomeMail
        ) {
            setIsSendMail(true);
            UpdateScreenValues({ isAlreadySendWelcomeMail: true });

            const isOrienteDomain =
                window.location.hostname.includes("oriente");

            const userName = quotationScreenValues.name
                ? quotationScreenValues.name
                : quotationScreenValues.nombreAsegurado;

            console.log(
                "1- se esta enviando el mail de bienvenida a ",
                userName
            );
            console.log(
                "2- se esta enviando el mail de bienvenida a ",
                quotation.lead
            );
            console.log("3- lead id: ", lead.data._id);
            console.log("4- quotationScreenValues: ", quotationScreenValues);

            const orienteMail = `
				<div>
				<p>
				<strong>Hola ${userName},</strong>
				</p>
				<p>
				Estás a un paso de sumar a Oriente Seguros como tu socio estratégico.
				</p>
				<p>
				Hemos elaborado la mejor propuesta para ti, revisa el PDF adjunto.
				</p>
				<p>
				En caso de cualquier inquietud o requerimiento, por favor comunícate a
				<a href="mailto:oriente@orienteseguros.com">oriente@orienteseguros.com</a>
				o al 1800 ORIENTE
				</p>
				<p>
				Estamos a tu lado mientras alcanzas tus metas y objetivos.
				</p>
				<p>
				Oriente Seguros, seguros de lo que somos.
				</p>
				</div>
				`;

            const redirectleadURL = `${settings.quotationUrlBase}lead/${lead.data._id}`;

            console.log(
                "********************redirectleadURL: ",
                redirectleadURL
            );

            const abankMail = `
				<div>
				<p>
				<span style="font-weight: bold">Hola ${userName},</span>
				</p>
				<p>Gracias por cotizar con Aseguradora ABANK.</p>
				</br>
				<p>Estamos a tu lado mientras alcanzas tus metas y objetivos.</p>
				</br>
				</br>
				Recuperar cotizacion: <a href="${redirectleadURL}">Aqui</a>
				</div>
				`;

            const firstPlan =
                quotation.products && quotation.products.length
                    ? quotation.products[0]
                    : {};
            const lifetimeMax = firstPlan.coverages.find(
                (coverages) => coverages.name === "Máximo Vitalicio"
            );

            if (quotation && quotation.creationDate) {
                quotationData = { ...quotation };
                quotationData.creationDate = new Date(
                    quotation.creationDate
                ).toLocaleDateString();
            }

            const { coverages, products } =
                getDataToPdfCotizacion(quotationData);

            const dataToPdf = {
                name: insurances.data[0].businessTitle.includes("Oriente")
                    ? "Cotizador Oriente"
                    : "Cotizacion GM - Abank",
                // name: template.data.name,
                templateData: {
                    quotation: quotationData,
                    products,
                    coverages,
                    dateCurrent: moment().format("l"),
                    plan: {
                        name: firstPlan.name,
                        price: (firstPlan.price / 12).toFixed(2),
                        frequency: "mensual",
                        premium: firstPlan.premium,
                        lifetimeMax: lifetimeMax
                            ? lifetimeMax.value
                            : undefined,
                    },
                    person: {
                        name: userName,
                    },
                    validity: {
                        start: {
                            day: `${now.format("DD")}`,
                            month: now.get("month") + 1,
                            monthLabel: `${now.format("MMMM")}`,
                            year: now.get("y"),
                            time: `${now.format("hh:mm A")}`,
                        },
                        end: {
                            day: `${end.format("DD")}`,
                            month: end.get("month") + 1,
                            monthLabel: `${end.format("MMMM")}`,
                            year: end.get("y"),
                            time: `${now.format("hh:mm A")}`,
                        },
                    },
                },
            };

            const templates = [].concat(dataToPdf);

            const headers = { "Content-Type": "application/json" };

            const emailTemplate = Handlebars.compile(template.data.newTemplate);
            const dataEmail = { redirectleadURL, name: userName };

            await axios({
                method: "post",
                headers,
                url: `${settings.quotationUrlApi}contact/quotation/send`,
                data: {
                    templates,
                    emailDataToSend: {
                        subject: template.data?.subject
                            ? template.data.subject
                            : "Bienvenido!",
                        // message: isOrienteDomain ? orienteMail : abankMail,
                        message: emailTemplate(dataEmail),
                        email: quotation.lead,
                        filename: "plan.pdf",
                    },
                },
            });
        }
    };

    const UpdateScreenValues = async (data) => {
        const quotationId = params.resultQuoteId;
        await dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: data,
                _id: quotationId,
            })
        );
    };

    const getQuoteInfo = async () => {
        const quotationId = params.resultQuoteId;
        dispatch(getQuote(quotationId));
    };

    const initInsurance = () => {
        if (quotation.insuranceId !== undefined) {
            dispatch(getInsuranceById(quotation.insuranceId));
        }
    };

    const handleChangeIndex = (index) => {
        setIndex(index);
    };

    const handleSelectPlan = (index = 0, plan = products[0]) => {
        setPlanCard(index);
        dispatch(patchPlan(plan));
        updateStateQuotation(plan._id);
    };

    const updateStateQuotation = (idPlan) => {
        dispatch(
            updateQuotation({ ...quote.quotation, productSelected: idPlan })
        );
    };

    const goToNextPage = async (idPlan) => {
        const savedQuotation =
            JSON.parse(
                sessionStorage.getItem(`quotation::quote::${riskType}`)
            ) || quotation;

        const planSelected = products.find((product) => product._id === idPlan);
        sessionStorage.setItem(
            `quotation::quote::${riskType}`,
            JSON.stringify({
                plan: planSelected,
                quotation: {
                    ...savedQuotation.quotation,
                    productSelected: planSelected._id,
                },
            })
        );

        updateStateQuotation(planSelected._id);
        dispatch(
            putUpdateQuotation({
                ...savedQuotation.quotation,
                productSelected: planSelected._id,
            })
        );

        if (riskType.toLowerCase().includes("medicos")) {
            navigate("recognition");
        } else {
            navigate("client-info");
        }
    };

    const goToPrevPage = () => {
        navigate(
            `/embed/quote/${riskType.toLowerCase()}/${currentInsurance.quotationScreenId
            }/${currentInsurance._id}/${currentInsurance.formBuilderId}/info`
        );
    };

    const getCoverages = (groupCoverages) => {
        switch (quotationSectionType) {
            case "coverageName":
                return (
                    <CheckCoverage coverages={groupCoverages} index={index} />
                );
            case "coverageCharge":
                return (
                    <AmountCoverage coverages={groupCoverages} index={index} />
                );
            default:
                return (
                    <AmountCoverage coverages={groupCoverages} index={index} />
                );
        }
    };

    const setDefaultFormat = () => {
        if (quotationScreenValues.hasOwnProperty("hash")) {
            // hash property is not needed by client, remove for this view
            delete quotationScreenValues.hash;
        }

        const formatText = Object.keys(quotationScreenValues)
            .map(
                (key) =>
                    `<strong>${key}:</strong> ${quotationScreenValues[key]}`
            )
            .join(", ");
        return `<p className="quote__description">${formatText}</p>`;
    };

    const getItemsString = (items) => {
        return items.reduce(
            (str, curr, itemsIndex) =>
            (str += Object.values(curr).reduce(
                (_str, _curr, i) =>
                (_str += `${i > 0 ? " - " : ""}${_curr}${i === Object.values(curr).length - 1 &&
                        itemsIndex < items.length - 1
                        ? ", "
                        : ""
                    }`),
                ""
            )),
            ""
        );
    };

    const setHeaderTextInFormat = () => {
        if (!insurances.data.length || !quotationScreenValues) {
            return "";
        }
        const insurance = currentInsurance;
        let textFormated =
            insurance && insurance.format && insurance.format.header
                ? insurance.format.header.toString()
                : false;

        if (!textFormated) {
            return setDefaultFormat();
        }

        const formatProperties = [];
        const dataFormatHeader = {};

        for (const property in quotationScreenValues) {
            formatProperties.push(property);
            dataFormatHeader[property] = quotationScreenValues[property];
        }

        if (
            formatProperties.includes("items") &&
            textFormated.includes("${{items}}")
        ) {
            let str = buildHeaderFormat();
            textFormated = textFormated.replace("${{items}}", str);
        }

        const textFormatedWithOut$ = textFormated.replace(/\$/g, "");
        const template = Handlebars.compile(textFormatedWithOut$);
        const renderTemplate = template(dataFormatHeader);

        return `<p className = "quote__description">${renderTemplate}</p >`;
    };

    const getKey = (keys, match) => {
        return keys.find((key) => key.includes(match));
    };

    const buildHeaderFormat = () => {
        let str = "";

        quotationScreenValues.items.map((item, index) => {
            const keys = Object.keys(item).filter(
                (key) =>
                    key.includes("name") ||
                    key.includes("age") ||
                    key.includes("spouse") ||
                    key.includes("genero")
            );
            str += `${index > 0 ? " -" : ""} ${item.groupName}: ${item[getKey(keys, "name")]
                } `;

            if (item.groupName === "conyuge") {
                str += ` - ${item[getKey(keys, "spouse")]}`;
            } else {
                str += ` - ${item[getKey(keys, "age")]}`;
            }

            if (getKey(keys, "genero")) {
                str += ` - ${item[getKey(keys, "genero")]}`;
            }
        });

        return str;
    };

    const setFooterTextInFormat = () => {
        if (!insurances.data.length || !quotationScreenValues) {
            return "";
        }

        const insurance = currentInsurance;
        var textFormated =
            insurance && insurance.format && insurance.format.footer
                ? insurance.format.footer.toString()
                : false;

        if (!textFormated) {
            return setDefaultFormat();
        }

        for (const property in quotationScreenValues) {
            const toSearch = "$" + `{{${property}}}`;
            const replaceFor = quotationScreenValues[property];
            textFormated = textFormated.replace(
                toSearch,
                Array.isArray(replaceFor)
                    ? getItemsString(replaceFor)
                    : replaceFor
            );
        }

        return `<p className ="quote__footer"> ${textFormated}</p>`;
    };

    const qtyItemsByScreenWidth = () => {
        const width = window.innerWidth;
        let qty = 3;
        if (width < 1000 && width > 780) {
            qty = 2;
        }
        if (width < 779) {
            qty = 1;
        }
        return qty;
    };

    const getDataToPdfCotizacion = (quotationData) => {
        let productsInformation = {};
        let coverages = {};
        let coveragesOrdered = [];

        productsInformation = quotationData.products.map((product) => {
            return { name: product.name, price: product.price };
        });

        const productOrder = productsInformation.map((product) => product.name);

        coverages = quotationData.products.map((product) => {
            product.coverages.map(
                (coverage) => (coverage.productName = product.name)
            );
            return product.coverages;
        });

        coverages.map((coverage) => coveragesOrdered.push(...coverage));

        coveragesOrdered.sort((a, b) => {
            if (a.name > b.name) {
                return -1;
            }

            if (a.name < b.name) {
                return 1;
            }

            return 0;
        });

        let currentCoverageName = coveragesOrdered[0].name;
        let coverageByProduct = productsInformation.map(() => { });
        let result = [];

        while (coveragesOrdered.length > 0) {
            if (currentCoverageName !== coveragesOrdered[0].name) {
                if (coverageByProduct[0] === undefined) {
                    coverageByProduct[0] = { name: currentCoverageName };
                } else {
                    console.log("coverageByProduct[0]", coverageByProduct[0]);
                }

                currentCoverageName = coveragesOrdered[0].name;

                result.push(coverageByProduct);
                coverageByProduct = productsInformation.map(() => { });
            } else {
                const coverage = coveragesOrdered.shift();
                const index = productOrder.findIndex(
                    (product) => product === coverage.productName
                );
                coverageByProduct[index] = coverage;
            }
        }

        return {
            coverages: result,
            products: productsInformation,
        };
    };

    return (
        <Grid container justify="center" className="appContainer">
            {(quote.loading || quoteAllPlans.loading) && <LayoutLoading />}
            {quote.error && <LayoutError />}
            {quotation && !quote.loading && products && (
                <div className="separated-plans-container">

                    <div className="embed-button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={goToPrevPage}
                        >
                            Volver a cotizar
                        </Button>
                    </div>

                    {/* <Title label="¡Seleccioná tu plan!" /> */}
                    {ReactHtmlParser(setHeaderTextInFormat())}
                    {/* <span className="date-display" style={{ color: config.theme.primary.main }}>Fecha <b>{now}</b></span> */}
                    {Object.keys(currentInsurance).length > 0 && (
                        <div className="products-carousel">
                            <CustomCarousel
                                items={products}
                                optionsByQuote={optionsByQuote}
                                placeholder="Consultando planes"
                                selectedItem={planCard}
                                handleSelect={handleSelectPlan}
                                index={index}
                                handleChangeIndex={handleChangeIndex}
                                type="plan"
                                handleNext={goToNextPage}
                                separated={
                                    currentInsurance.options
                                        .quotationSectionType !== "simple"
                                }
                                quotation={quotation}
                                insurance={currentInsurance}
                            />
                            {currentInsurance.options.quotationSectionType ===
                                "simple" && (
                                    <Button
                                        style={{
                                            width: "100%",
                                            display: "block",
                                            height: 54,
                                            marginTop: 8,
                                        }}
                                        variant="contained"
                                        onClick={goToNextPage}
                                        color="primary"
                                    >
                                        Contratar
                                    </Button>
                                )}
                        </div>
                    )}
                    <div className="products-tabs">
                        <ProposalTabs
                            items={products}
                            handleSelect={handleSelectPlan}
                            handleNext={goToNextPage}
                            selectedItem={planCard}
                            quotation={quotation}
                            optionsByQuote={optionsByQuote}
                            insurance={currentInsurance}
                        />
                    </div>
                    <div className="desktop-footer">
                        {ReactHtmlParser(setFooterTextInFormat())}
                    </div>
                    <div className="mobile-footer">
                        {ReactHtmlParser(setFooterTextInFormat())}
                    </div>
                </div>
            )}
        </Grid>
    );
};

export default ProposalComponent;
