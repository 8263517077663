import React, { useEffect, useState } from 'react';
import { List, ListItem, ListSubheader, ListItemText, Tooltip, IconButton, DialogContent, DialogContentText, DialogActions, Button, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Handlebars from "handlebars";
import { ArrowForwardIos, Input } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
      }
    }
  },
  search: {
    marginBottom: '12px'
  },
  modal: {
    borderRadius: 15
  },
  modalMessage: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    textAlign: 'center',
    color: '#4B4B4B',
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  },
  form: {
    marginLeft: 16,
    '& > div': {
      display: 'inline-flex',
      flexDirection: 'row'
    }
  },
  customContainer: {
    width: '100%',
    marginTop: 15,
    display: 'inline-flex',
    flexWrap: 'wrap',
    '& > div': {
      margin: '0px 16px',
      [theme.breakpoints.down('xs')]: {
        margin: 0
      }
    }
  },
  customBox: {
    minHeight: 135,
    border: '1px solid #BDAFA2',
    backgroundColor: '#fff',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 40px',
    cursor: 'pointer',
    flex: 1,
    '&.selected': {
      borderColor: theme.palette.primary.dark
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px'
    }
  },
  topIndicator: {
    position: 'absolute',
    top: 6,
    right: 8
  }
}));

const QuoteListBoolean = ({
  data,
  handleSelect,
  label,
  name,
  isOptional,
  isSearchable,
  quotationScreenOptions,
  handlePreSelect,
  preSelectedValue,
  controlType,
  handleNextButtonValid
}) => {
  const classes = useStyles();
  const [showMessage, setShowMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const config = useSelector(state => state.config.data);

  useEffect(() => {
    if (isOptional) {
      if (quotationScreenOptions.nextButtonType === 'ext') {
        handleNextButtonValid(true);
      }
    }
  }, []);

  const handleShowMessage = (bool, index) => {
    setShowMessage(true);
    setSelectedOption(bool);
    setSelectedIndex(index);
    handleNextButtonValid(true);
  };

  const handleCloseMessage = () => {
    quotationScreenOptions.nextButtonType === 'int' ? handleSelect(Boolean(selectedOption)) : handlePreSelect(Boolean(selectedOption));
    setShowMessage(false);
    setSelectedOption(null);
  };

  const handleCancel = () => {
    setShowMessage(false);
    setSelectedOption(null);
  };

  const handleChange = (bool, index) => {
    quotationScreenOptions.nextButtonType === 'int' ? handleSelect(Boolean(bool)) : handlePreSelect(Boolean(bool));
    setSelectedIndex(index);
    if (quotationScreenOptions.nextButtonType === 'ext') {
      handleNextButtonValid(true);
    }
  };

  const getTemplate = (str) => {
    const template = Handlebars.compile(str);
    return template();
  };

  const options = data.slice(0, 2);
  const messages = {
    affirmative: data[2],
    negative: data[3]
  };

  const getLayoutByType = () => {
    switch (controlType) {
      case 'chip':
        return (
          <List
            component="nav"
            className="quoteList"
            subheader={
              <ListSubheader className={classes.search} component="h2">
                {(config.theme && config.theme.labelPosition) ? label : (
                  <div className={classes.label}>
                    <ArrowForwardIos color="primary" />
                    <InputLabel id="external-input-label">{label}</InputLabel>
                  </div>)} {(isOptional && quotationScreenOptions.nextButtonType === 'int') && <Tooltip title={'Siguiente'} arrow open placement="top">
                    <IconButton
                      aria-label="check input value"
                      onClick={() => handleChange(null)}
                      style={{
                        color: config.theme.primary.main
                      }}
                    >
                      <Input />
                    </IconButton>
                  </Tooltip>}
              </ListSubheader>
            }
          >
            {
              options.map((item, index) => {
                return (
                  <ListItem selected={index === selectedIndex} className={classes.listItem} button key={index} onClick={() => messages.affirmative ? handleShowMessage(!index, index) : handleChange(!index, index)}>
                    <ListItemText primary={item} />
                  </ListItem>
                )
              })
            }
          </List>
        );
      case 'simple':
        return (
          <div>
            {(config.theme && config.theme.labelPosition) ? label : (
              <div className={classes.label}>
                <ArrowForwardIos color="primary" />
                <InputLabel id="external-input-label">{label}</InputLabel>
              </div>)}
            <FormControl className={classes.form}>
              <RadioGroup value={selectedIndex} onChange={({ target: { value } }) => messages.affirmative ? handleShowMessage(value == 0, Number(value)) : handleChange(value == 0, Number(value))}>
                {options.map((option, index) => ({ value: index, label: option })).map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        );
      case 'custom':
        return (
          <div>
            {(config.theme && config.theme.labelPosition) ? label : (
              <div className={classes.label}>
                <ArrowForwardIos color="primary" />
                <InputLabel id="external-input-label">{label}</InputLabel>
              </div>)}
            <div className={classes.customContainer}>
              {options.map((option, index) => (
                <div className={`${classes.customBox} ${index === selectedIndex ? 'selected' : ''}`} onClick={() => messages.affirmative ? handleShowMessage(index == 0, Number(index)) : handleChange(index == 0, Number(index))}>
                  <div className={classes.topIndicator}><Radio color="primary" checked={index === selectedIndex} style={{ padding: 0 }} /></div>
                  <div dangerouslySetInnerHTML={{ __html: getTemplate(option) }} />
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return (<span>No Layout</span>)
    }
  };

  return (
    <>
      {getLayoutByType()}
      <Dialog onClose={handleCancel} open={showMessage} classes={{ paper: classes.modal }} maxWidth="xs">
        <DialogContent>
          {selectedOption !== null && <DialogContentText className={classes.modalMessage}><div dangerouslySetInnerHTML={{ __html: selectedOption ? getTemplate(messages.affirmative) : getTemplate(messages.negative) }} /></DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage} color="primary">Continuar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuoteListBoolean;
