import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  title: {
    marginTop: 24
  }
}));

const ModalDetails = ({ open, handleClose, coverage }) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
    >
      <IconButton className={classes.close}><Close onClick={handleClose}  /></IconButton>
      <DialogTitle className={classes.title}>{coverage.name}</DialogTitle>
      <DialogContent>
        <Typography style={{ wordBreak: 'break-word' }} variant="body1" component="p"  dangerouslySetInnerHTML={{ __html: coverage.content }} />
      </DialogContent>
    </Dialog>
  );
};

export default ModalDetails;